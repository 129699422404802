import { CheckmarkOutline, CloseOutline, Edit, Filter, TrashCan, View } from '@carbon/icons-react';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTablePageEvent, DataTableSortEvent } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import {
  Confirm,
  InheritedMenu,
  PaginatorTemplate,
  Status as StatusComp,
  TableSearch,
  WarningIcon,
} from '../../../../components';
import FilterModal from '../../../../components/FilterModal';
import { Translate } from '../../../../i18n/translate';
import { Download, MoreAction } from '../../../../icons';
import { useAppDispatch } from '../../../../store';
import { selectCurrentCustomer, sendToast } from '../../../../store/slices/main/mainSlice';
import { selectCustomerCurrencies } from '../../../../store/slices/refdata/refdataSlice';
import { Status } from '../../../../types';
import { enumToCamelCase } from '../../../../utils/helper';
import ManagePricingService from '../managePricingService';
import managePricingRole from '../managePricingRole';
import { STATUS_OPTIONS } from '../../configurations-constants';
import { FEES_TYPE_OPTIONS } from './formConstants';

const Fees = ({
  isCustomerPricing,
  client,
  refreshData,
  onEdit,
  onView,
}: {
  isCustomerPricing: boolean;
  client: Client | undefined;
  refreshData: boolean;
  onEdit: (fees: Fees) => void;
  onView: (fees: Fees) => void;
}) => {
  const [fees, setFees] = useState<Fees[]>([]);
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const [currentActionFees, setCurrentActionFees] = useState<Fees | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const menu = useRef<Menu>(null);
  const [filters, setFilters] = useState({});
  const [filterValues, setFilterValues] = useState<any>();
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [isModalVisible, setModalVisible] = useState(false);
  const [globalSearch, setGlobalSearch] = useState('');
  const [debouncedSearch] = useDebounce(globalSearch, 300);
  const currencies = useSelector(selectCustomerCurrencies);
  const [actionItems, setActionItems] = useState<MenuItem[]>([]);
  const dispatch = useAppDispatch();

  const [pageParams, setPageParams] = useState<PageParams>({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortParams, setSortParams] = useState<SortParams>({
    sortField: '',
    sortOrder: 0,
  });

  useEffect(() => {
    if (selectedCustomer) getFees(pageParams);
  }, [selectedCustomer, pageParams, debouncedSearch, filters, sortParams]);

  useEffect(() => {
    if (refreshData) {
      getFees(pageParams);
    }
  }, [refreshData]);

  const getFees = async (params: PageParams) => {
    setLoading(true);
    if (selectedCustomer?.id) {
      try {
        const { page, rows } = params;
        const payload = {
          searchText: globalSearch,
          ...filters,
        };
        const data = await ManagePricingService.getCustomerFees(
          isCustomerPricing && client && client.id ? client.id : selectedCustomer.id,
          page,
          rows,
          payload,
          sortParams,
        );
        if (data) {
          const { content, totalElements } = data;
          setFees(content);
          setTotalRecords(totalElements);
        } else {
          setFees([]);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const generateFilterMetaData = () => {
    const _currencies: Currency[] = currencies.reduce((filteredCurrencies, currency) => {
      if (
        currency.isocode !== 'ALL' &&
        !filteredCurrencies.some((item) => item.value === currency.isocode)
      ) {
        filteredCurrencies.push({
          label: currency.isocode,
          value: currency.isocode,
        });
      }
      return filteredCurrencies;
    }, [] as Currency[]);
    return [
      {
        header: 'By Type',
        fields: [
          {
            name: 'type',
            placeholder: 'Select Fee type',
            type: 'single',
            value: '',
            options: FEES_TYPE_OPTIONS,
          },
        ],
      },
      {
        header: 'By Currency',
        fields: [
          {
            name: 'currency',
            type: 'currency',
            placeholder: 'Select Currency',
            value: [],
            options: _currencies,
          },
        ],
      },
      {
        header: 'By Status',
        fields: [
          {
            name: 'status',
            type: 'single',
            placeholder: 'Select Status',
            options: STATUS_OPTIONS,
          },
        ],
      },
    ];
  };

  useEffect(() => {
    const filterMetaData = generateFilterMetaData();
    setFilterValues(filterMetaData);
  }, [currencies]);

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const activateOrDeactivate = async (fees: Fees, _status: Status) => {
    if (fees && fees.id) {
      const { status, data } = await ManagePricingService.updateFeeStatus(fees.id, _status);
      if (status != 200 || data.result.success === false) {
        showToast(
          'success',
          _status == Status.ACTIVE
            ? 'fees.activate.error.toast.title'
            : 'fees.deactivate.error.toast.title',
          '',
          {
            feeType: fees.feeType,
          },
        );
        return;
      } else {
        showToast(
          'success',
          _status == Status.ACTIVE
            ? 'fees.activate.success.toast.title'
            : 'fees.deactivate.success.toast.title',
          '',
          {
            feeType: fees.feeType,
          },
        );
        getFees(pageParams);
      }
    }
  };

  const actionBodyTemplate = (fees: Fees) => {
    const items: MenuItem[] = [
      {
        label: 'View',
        icon: <View />,
        onClick: () => {
          if (currentActionFees) onView(fees);
        },
        roles: managePricingRole.ViewManagePricing,
      },
      {
        label: 'Edit',
        icon: <Edit />,
        onClick: () => {
          if (currentActionFees) onEdit(fees);
        },
        roles: managePricingRole.EditManagePricing,
      },
      {
        label: 'activate',
        icon: <CheckmarkOutline />,
        onClick: () => {
          activateOrDeactivate(fees, Status.ACTIVE);
        },
        roles: managePricingRole.EditManagePricing,
      },
      {
        label: 'deactivate',
        icon: <CloseOutline />,
        onClick: () => {
          activateOrDeactivate(fees, Status.INACTIVE);
        },
        roles: managePricingRole.EditManagePricing,
      },
      {
        label: 'delete',
        icon: <TrashCan />,
        onClick: () => {
          setShowDeleteDialog(true);
        },
        className: 'delete-menu-item',
        roles: managePricingRole.DeleteManagePricing,
      },
    ];

    const actionFilter = items.filter((item) => {
      if (fees.status === Status.PENDING) {
        return item.label !== 'activate' && item.label !== 'deactivate';
      } else if (fees.status == Status.ACTIVE) {
        return item.label !== 'activate';
      } else if (fees.status == Status.INACTIVE) {
        return item.label !== 'deactivate';
      }
      return true;
    });

    return (
      <div>
        <div
          onClick={(event) => {
            setActionItems(actionFilter);
            setCurrentActionFees(fees);
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={actionItems} ref={menu} popupAlign='left' />
      </div>
    );
  };

  const deleteFees = async (fees: Fees) => {
    if (fees.id) {
      const { data, status } = await ManagePricingService.deleteFee(fees.id);
      if (status != 200 || data.result.success === false) {
        showToast('success', 'fees.delete.success.toast.title', '', {
          feeType: fees.feeType,
        });
        return;
      } else {
        showToast('success', 'fees.delete.success.toast.title', '', {
          feeType: fees.feeType,
        });
        getFees(pageParams);
      }
    }
  };

  const handleApplyFilters = (formdata: FilterFormData) => {
    const filterCount = Object.values(formdata).filter((arr) => arr?.length).length;
    setActiveFilterCount(filterCount);
    setFilters(formdata);
  };

  const header = (
    <div className='flex justify-between'>
      <div className='text-neutral-1 text-lg-semibold'>
        <Translate value='fees' />
        <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
          {totalRecords}
        </span>
      </div>
      <div className='flex justify-around gap-2 items-center'>
        <TableSearch
          globalFilterValue={globalSearch}
          onGlobalFilterChange={(e) => {
            setGlobalSearch(e.target.value);
          }}
        />
        <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
          <Button
            severity='secondary'
            className='p-button-text'
            onClick={() => {
              setModalVisible(true);
            }}
          >
            <Filter /> <Translate value='filterLabel' className='ml-1' />
          </Button>
          {!!activeFilterCount && (
            <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
          )}
        </div>
        <div>
          <Button
            severity='contrast'
            onClick={() => {
              downloadFees();
            }}
          >
            <Download /> <Translate value='downloadLabel' />
          </Button>
        </div>
      </div>
    </div>
  );

  const onPage = (event: DataTablePageEvent) => {
    setPageParams({
      ...event,
      page: event.page ?? 0,
      rows: event.rows ?? 10,
      first: event.first ?? 0,
    });
  };

  const onSort = (event: DataTableSortEvent) => {
    const { sortField, sortOrder } = event;
    if (sortField && sortOrder) {
      setSortParams({
        sortField,
        sortOrder,
      });
    }
  };

  const downloadFees = async () => {
    const id = isCustomerPricing && client && client.id ? client.id : selectedCustomer?.id;
    const payload = {
      searchText: globalSearch,
      ...filters,
    };
    if (id) {
      await ManagePricingService.downloadTableData(id, payload, 'fees');
    }
  };

  return (
    <>
      <Confirm
        confirmLabel='Delete'
        headerIcon={<WarningIcon />}
        title={`Delete '${enumToCamelCase(currentActionFees?.feeType || '')}'?`}
        message='Are you sure you want to delete this fee?'
        confirmButtonSeverify='danger'
        onCancel={() => {
          setShowDeleteDialog(false);
        }}
        onConfirm={() => {
          if (currentActionFees) {
            deleteFees(currentActionFees);
          }
          setShowDeleteDialog(false);
        }}
        visible={showDeleteDialog}
      />
      <FilterModal
        visible={isModalVisible}
        setFilterModalToggle={setModalVisible}
        filters={filterValues}
        setFilters={setFilterValues}
        title='clientFilter'
        onApply={handleApplyFilters}
      />
      <div className='table-card'>
        <DataTable
          value={fees}
          paginator={true}
          lazy
          paginatorTemplate={PaginatorTemplate}
          first={pageParams.first}
          rows={pageParams.rows}
          loading={loading}
          totalRecords={totalRecords}
          header={header}
          onPage={onPage}
          onSort={onSort}
          sortField={sortParams.sortField}
          sortOrder={sortParams.sortOrder}
        >
          <Column
            field='feeType'
            header='Fee Type'
            body={(fees: Fees) => <div>{enumToCamelCase(fees.feeType)}</div>}
            sortable
          />
          <Column field='description' header='Description' sortable />
          <Column field='currency' header='Currency' sortable />
          <Column field='feeCurrency' header='Fee CCY' sortable />
          <Column field='amount' header='Amount' sortable />
          <Column
            field='status'
            header='Status'
            body={(margin: Margin) => <StatusComp status={margin.status ?? ''} />}
          />
          <Column header='' body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
    </>
  );
};

export default Fees;
