import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ClientService from '../../modules/Client/clientService';
import { useAppDispatch } from '../../store';
import { fethCurrentUser } from '../../store/slices/main/actions';
import {
  selectCurrentCustomer,
  sendToast,
  setCurrentCustomer,
} from '../../store/slices/main/mainSlice';
import { injectAoboCustoemrID } from '../../utils/apiClient';
import DropdownInput from '../DropdownInput';

interface Option {
  value: string;
  label: string;
}

export const Aobo = () => {
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const [customers, setCustomers] = useState<Option[]>([]);
  const [hierarchyCustomers, setHierarchyCustomers] = useState<Client[] | undefined>([]);
  const [aoboCustomer, setAoboCustomer] = useState<Option | undefined>();
  const [parentClient] = useState<Client | null>(selectedCustomer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getChildrenCustomers();
  }, []);

  const getChildrenCustomers = async () => {
    if (selectedCustomer && selectedCustomer.id) {
      const hierarchy = await ClientService.getCustomerHierarchy(selectedCustomer.id);
      if (hierarchy) {
        const children = hierarchy.children;
        setHierarchyCustomers(children);
        delete hierarchy.children;
        children?.unshift(hierarchy);
        const options = children?.map((child) => ({
          value: child.id?.toString() || '',
          label: child.customerShortName,
        }));
        setCustomers(options || []);
        setAoboCustomer(options?.[0]);
      }
    }
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  useEffect(() => {
    console.log('parentClient', parentClient);
  }, [parentClient]);

  return (
    <>
      {parentClient && customers.length != 0 && (
        <DropdownInput
          onChange={(e) => {
            setAoboCustomer(customers.find((customer) => customer.value === e.value));
            if (parentClient.id == parseInt(e.value)) {
              injectAoboCustoemrID(null);
              showToast('success', 'aobo.remove.title', 'aobo.remove.description');
              dispatch(fethCurrentUser());
              dispatch(setCurrentCustomer(parentClient));
              navigate('/');
            } else {
              const label = customers.find((customer) => customer.value === e.value)?.label;
              showToast('success', 'aobo.title', 'aobo.description', {
                clientName: label || '',
              });
              injectAoboCustoemrID(parseInt(e.value));
              dispatch(fethCurrentUser());
              const aoboClient = hierarchyCustomers?.find((client) => client.id == e.value);
              if (aoboClient) {
                dispatch(setCurrentCustomer({ ...aoboClient, aobo: true }));
              }
              navigate('/');
            }
          }}
          className='min-w-[250px]'
          placeholder='Select customer'
          options={customers}
          value={aoboCustomer?.value}
          filter={true}
        ></DropdownInput>
      )}
    </>
  );
};
