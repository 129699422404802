import { Menu } from 'primereact/menu';
import React, { forwardRef } from 'react';
import useRole from '../../hooks/useRoles';
import { Translate } from '../../i18n/translate';
import { useAppDispatch } from '../../store';
import { sendToast } from '../../store/slices/main/mainSlice';
import './Menu.css';

interface MenuProps {
  items: MenuItem[];
  popupAlign?: 'left' | 'right';
}
// eslint-disable-next-line react/display-name
const InheritedMenu = forwardRef<Menu | null, MenuProps>(({ items, popupAlign = 'left' }, ref) => {
  const { hasRole } = useRole();
  const dispatch = useAppDispatch();

  const updatedItems = items.map((item) => ({
    ...item,
    template: (
      <_Template
        disabled={item.disabled ?? false}
        label={item.label}
        icon={item.icon}
        className={item.className ?? ''}
        onClick={(event: React.MouseEvent) => {
          if (item.disabled) {
            dispatch(
              sendToast({
                severity: 'error',
                summary: 'Warning',
                detail: 'item.disabled.action',
              }),
            );
            return;
          }
          if (item.onClick) {
            item.onClick(event);
          }
          if (ref && 'current' in ref && ref.current) {
            ref.current.toggle(event);
          }
          event.stopPropagation();
        }}
      />
    ),
  }));

  const filterRoles = updatedItems.filter((i) => (i.roles ? hasRole(i.roles) : true));
  return <Menu model={filterRoles} popup ref={ref} popupAlignment={popupAlign} />;
});

const _Template = ({
  disabled,
  label,
  icon,
  className,
  onClick,
}: {
  disabled: boolean;
  label: string;
  icon: React.ReactNode;
  className: string;
  onClick?: (event: React.MouseEvent) => void;
}) => (
  <div className='p-menuitem-content' onClick={onClick}>
    <span
      className={`p-menuitem-link ${className} ${disabled ? 'opacity-[.4] !pointer-events-none' : ''}`}
    >
      {icon} <span className={`${icon ? 'ml-2' : ''}`}>{<Translate value={label} />}</span>
    </span>
  </div>
);

export default InheritedMenu;
