import apiClient from '../../../utils/apiClient';
import { handleApiResponse } from '../../../utils/helper';

const ROOT = '/api/approval-management/';
const APPROVAL_MODEL = `${ROOT}model`;
const APPROVAL_MODELS = `${ROOT}models`;

const ApprovalsSerive = {
  async getUsersByFeature(feature: string): Promise<User[]> {
    try {
      const response = await apiClient.post(
        `api/customer-role-management/roles/users-by-feature?feature=${feature}`,
      );
      return handleApiResponse<User[]>(response);
    } catch (error) {
      console.error(`Error fetching users by feature ${feature}`, error);
      throw error;
    }
  },

  async getRolesByFeature(feature: string): Promise<Role[]> {
    try {
      const response = await apiClient.post(
        `api/customer-role-management/roles/roles-by-feature?feature=${feature}`,
      );
      return handleApiResponse<Role[]>(response);
    } catch (error) {
      console.error(`Error fetching roles by feature ${feature}`, error);
      throw error;
    }
  },

  async createPaymentApprovalStructure(
    data: Omit<ApprovalStructure, 'id' | 'version' | 'createdDate' | 'approvalStatus'>,
  ) {
    try {
      const response = await apiClient.post(APPROVAL_MODEL, data);
      return handleApiResponse<ApprovalStructure>(response);
    } catch (error) {
      console.error('Error creating approval structure', error);
      throw error;
    }
  },

  async validateApprovalStructure(
    data: { id?: number } & Pick<
      ApprovalStructure,
      'owningCustomerId' | 'modelName' | 'appStructure' | 'approverCategory'
    >,
  ) {
    try {
      const response = await apiClient.post(`${APPROVAL_MODEL}/validate-structure`, data);
      if (response.data.success) {
        return true;
      }
      throw new Error(
        response.data.errors && response.data.errors[0] && response.data.errors[0].errorTextCode,
      );
    } catch (error) {
      console.error('Error updating approval structure', error);
      throw error;
    }
  },

  async updatePaymentApprovalStructure(data: ApprovalStructure) {
    try {
      const response = await apiClient.put(APPROVAL_MODEL, data);
      return handleApiResponse<ApprovalStructure>(response);
    } catch (error) {
      console.error('Error updating approval structure', error);
      throw error;
    }
  },

  async getApprovalStructures(feature: FeatureType) {
    try {
      const response = await apiClient.get(`${APPROVAL_MODELS}/feature/${feature}`);
      return handleApiResponse<Array<ApprovalStructure>>(response);
    } catch (error) {
      console.error('Error getting approval structures', error);
      throw error;
    }
  },

  async getApprovalStructureByModelId(id: number) {
    try {
      const response = await apiClient.get(`${APPROVAL_MODEL}/${id}`);
      return handleApiResponse<ApprovalStructure>(response);
    } catch (error) {
      console.error('Error getting approval structure', error);
      throw error;
    }
  },

  getApprovalItemDetails(recordId: number) {
    return apiClient.get(
      `/api/approval-management/approvals/feature/MANAGE_APPROVAL_WORKFLOW/pending-item/${recordId}`,
    );
  },

  async addNotifiers(modelId: number, userIds: Array<number>) {
    try {
      const response = await apiClient.post(`${APPROVAL_MODEL}/${modelId}/add-notifiers`, {
        userIds,
      });
      return handleApiResponse<ApprovalStructure>(response);
    } catch (error) {
      console.error('Validation error for approval structure', error);
      throw error;
    }
  },

  async deactivateApprovalStructure(ids: Array<number>) {
    return apiClient.post(`${APPROVAL_MODELS}/deactivate`, ids);
  },

  async delete(ids: number[]) {
    return apiClient.post(`${APPROVAL_MODEL}/delete-models`, ids);
  },

  async activateApprovalStructure(ids: Array<number>) {
    return apiClient.post(`${APPROVAL_MODELS}/activate`, ids);
  },

  updateApprovalStructureStatus(modelId: number, data: any) {
    return apiClient.post(`${ROOT}/approvals/${modelId}/approve`, data);
  },
};

export default ApprovalsSerive;
