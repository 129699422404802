import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CustomTabView } from '../../../components';
import { PageHeadContext } from '../../../providers/PageHead';
import { AddOrEditOrView, CustomerType } from '../../../types';
import ClientService from '../clientService';
import CustomerDetails from '../CustomerDetails';
import { CustomerOverview } from '../CustomerOverview';
import SiteDetails from '../SiteDetails';
import UserDetails from '../UserDetails';

const ViewClient = ({ mode }: { mode?: AddOrEditOrView }) => {
  const [customerOverview, setCustomerOveriew] = useState<CustomerOverviewFormData>();
  const [customerType, setCustomerType] = useState<CustomerType>();
  const [currentEditiCustomer, setCurrentEditiCustomer] = useState<Client>();
  const [canBeApproved, setCanBeApproved] = useState(false);
  const [, setHistory] = useState<any[]>([]);
  const context = useContext(PageHeadContext);

  const { setPageHeadData } = context;

  const { customerId } = useParams();

  useEffect(() => {
    getCutomerById();
  }, [customerId]);

  useEffect(() => {
    if (mode == AddOrEditOrView.Approve && currentEditiCustomer && currentEditiCustomer.id) {
      ClientService.getApprovalItemDetails(currentEditiCustomer?.id).then((response) => {
        const {
          data: {
            data: { data: client, histories: history },
          },
        } = response;
        setHistory(history);
        setCanBeApproved(client.canBeApprovedByCurrentUser ?? false);
      });
    }
  }, [mode, currentEditiCustomer]);

  const viewTabs = [
    {
      header: 'customer.details.tab.title',
      content: (
        <>
          <CustomerOverview
            currentEditiCustomer={currentEditiCustomer}
            setCustomerOveriew={setCustomerOveriew}
            setCustomerType={setCustomerType}
            mode={AddOrEditOrView.View}
            isApproveScreen={mode == AddOrEditOrView.Approve}
          />
          <div className='mt-2'></div>
          <CustomerDetails
            customerType={customerType}
            customerOveriew={customerOverview}
            mode={AddOrEditOrView.View}
            currentEditiCustomer={currentEditiCustomer}
          />
        </>
      ),
    },
    {
      header: 'user_details.title',
      content: <UserDetails mode={AddOrEditOrView.View} />,
    },
    {
      header: 'site_details.title',
      content: <SiteDetails mode={AddOrEditOrView.View} />,
    },
  ];

  const approveTabs = [
    {
      header: 'customer.details.tab.title',
      content: (
        <>
          <CustomerOverview
            currentEditiCustomer={currentEditiCustomer}
            setCustomerOveriew={setCustomerOveriew}
            setCustomerType={setCustomerType}
            mode={AddOrEditOrView.View}
            isApproveScreen={mode == AddOrEditOrView.Approve}
            canBeApproved={canBeApproved}
          />
          <div className='mt-2'></div>
          <CustomerDetails
            customerType={customerType}
            customerOveriew={customerOverview}
            mode={AddOrEditOrView.View}
            currentEditiCustomer={currentEditiCustomer}
          />
        </>
      ),
    },
  ];

  const getCutomerById = async () => {
    if (!customerId) {
      return;
    }
    const client = await ClientService.getClientById(customerId);
    if (client) {
      setCustomerType(client.customerType);
      setCurrentEditiCustomer(client);
      setPageHeadData({
        title: client.customerShortName ?? 'ediClient.title',
        description: 'view.client.description',
      });
    }
  };

  return (
    <>
      {mode && (
        <CustomTabView
          tabs={mode == AddOrEditOrView.View ? viewTabs : approveTabs}
          initialIndex={0}
        />
      )}
    </>
  );
};

export default ViewClient;
