import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { injectCustomerID } from '../../../utils/apiClient';
import { RootState } from '../../rootReducer';
import { fethCurrentUser } from './actions';

export interface MainState {
  error: string;
  isLoading: boolean;
  userPrinciple: UserPrinciple | null;
  owningCustomers: Client[];
  currentCustomer: Client | null;
  toast?: ToastData;
  branding: BrdingObject | null;
  oktaUser: OktaUser | null;
  roles: string[];
}

export const initialState: MainState = {
  error: '',
  isLoading: false,
  userPrinciple: null,
  owningCustomers: [],
  currentCustomer: null,
  toast: undefined,
  branding: null,
  oktaUser: null,
  roles: [],
};

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setError: (state: MainState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setLoading: (state: MainState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUserPrinciple: (state: MainState, action: PayloadAction<UserPrinciple>) => {
      state.userPrinciple = action.payload;
      state.owningCustomers = action.payload.owningCustomers;
    },
    setCurrentCustomer: (state: MainState, action: PayloadAction<Client | null>) => {
      state.currentCustomer = action.payload;
      if (state.currentCustomer && state.currentCustomer.id && !action.payload?.aobo) {
        injectCustomerID(state.currentCustomer?.id);
      }
    },
    sendToast: (state: MainState, action: PayloadAction<ToastData>) => {
      state.toast = action.payload;
    },
    setOktaUser: (state: MainState, action: PayloadAction<OktaUser>) => {
      state.oktaUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fethCurrentUser.fulfilled, (state, action) => {
      if (action.payload?.userPermissions) {
        state.roles = Object.keys(action.payload?.userPermissions)
          .map((key) => {
            const listOfActions = action.payload?.userPermissions[key];
            if (!listOfActions) {
              return key;
            }
            return listOfActions?.map((action) => `${key}_${action}`);
          })
          .flat();
      }
    });
  },
});

export const {
  setError,
  setUserPrinciple,
  setCurrentCustomer,
  setLoading,
  sendToast,
  setOktaUser,
} = mainSlice.actions;
export const selectLoading = (state: RootState) => state.main.isLoading;
export const selectToast = (state: RootState) => state.main.toast;
export const selectUserPrinciple = (state: RootState) => state.main.userPrinciple;
export const selectCurrentCustomer = (state: RootState) => state.main.currentCustomer;
export const selectBranding = (state: RootState) => state.main.branding;
export const selectOktaUser = (state: RootState) => state.main.oktaUser;
export const selectRoles = (state: RootState) => state.main.roles;
export const selectOwningCustomers = (state: RootState) => state.main.owningCustomers;
export default mainSlice.reducer;
