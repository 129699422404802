import { UserFollow } from '@carbon/icons-react';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { DropdownInput, TextInput, Toaster } from '../../../components';
import CountrySelect from '../../../components/CountrySelect';
import { Translate, translateWithValues } from '../../../i18n/translate';
import { useAppDispatch } from '../../../store';
import { selectLoading, sendToast } from '../../../store/slices/main/mainSlice';
import { selectCountries } from '../../../store/slices/refdata/refdataSlice';
import { AddOrEditOrView, ClientForms, CustomerType, Status } from '../../../types';
import { EMAIL_VALIDATION_REGEX, STATUS_OPTIONS } from '../clientConstants';
import ClientService from '../clientService';

interface UserDetailsProps {
  activeStep?: string;
  inheritedClientId?: number;
  mode?: AddOrEditOrView;
  visitPermitted?: Set<string>;
  visited?: Set<string>;
  customerType?: CustomerType;
  handleStepperValidation?: (formName: ClientForms, isValid: boolean) => void;
  onSaveAndProceed?: () => void;
  jumpToEdit?: (step: string) => void;
}

const UserDetails = ({
  activeStep,
  inheritedClientId,
  mode,
  visitPermitted,
  visited,
  customerType,
  handleStepperValidation,
  onSaveAndProceed,
  jumpToEdit,
}: UserDetailsProps) => {
  const userDefaults: UserDetailsFormData = {
    firstName: '',
    lastName: '',
    status: '' as Status,
    email: '',
    phone: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    country: undefined,
    postalCode: '',
  };
  const [users, setUsers] = useState<UserDetailsFormData[]>([userDefaults]);
  const selectedCountries = useSelector(selectCountries);
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const { customerId } = useParams();
  const toastRef = useRef<ToasterProps>(null);
  const [owningCustomerId, setOwningCustomerId] = useState<number>(0);
  const [customerHasUsers, setCustomerHasUsers] = useState<boolean>(false);
  const isLoading = useSelector(selectLoading);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isValid },
    trigger,
  } = useForm<UserDetailsFormData>();

  const formValues = useWatch({ control });

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  useEffect(() => {
    setUsers((prev) => {
      const updatedUsers = [...prev];
      updatedUsers[selectedUserIndex] = getValues();
      return updatedUsers;
    });
  }, [formValues, getValues, selectedUserIndex]);

  const fillUsersDatafromServer = (response: User[]) => {
    const notDeletedUsers = response?.filter((user: User) => user.status !== Status.DELETED);
    if (notDeletedUsers.length == 0) {
      return;
    }
    setCustomerHasUsers(true);
    const _users = notDeletedUsers.map((user: User) => {
      return {
        ...user,
        id: user.userIdentity.id,
        firstName: user.userIdentity.firstName,
        lastName: user.userIdentity.lastName,
        email: user.userIdentity.email,
        phone: user.userIdentity.mobilePhone,
        street1: user.address?.street1,
        street2: user.address?.street2,
        city: user.address?.city,
        state: user.address?.state,
        country: selectedCountries.find((c) => c.code == user.address?.country),
        postalCode: user.address?.postalCode,
      };
    });
    setUsers(_users);
    reset(_users[0]);
  };

  useEffect(() => {
    if (customerId && (mode == AddOrEditOrView.Edit || mode == AddOrEditOrView.View)) {
      setOwningCustomerId(parseInt(customerId));
      fetchUsers(customerId);
    }
  }, [customerId]);

  const fetchUsers = async (cId: string) => {
    if (!cId) {
      return;
    }
    const response = await ClientService.getUsersByCustomerId(cId);
    if (response) {
      fillUsersDatafromServer(response);
    }
  };

  useEffect(() => {
    if (inheritedClientId) {
      setOwningCustomerId(inheritedClientId);
      if (customerType == CustomerType.INSTRUCTING_INSTITUTION && mode == AddOrEditOrView.Add) {
        fetchUsers(inheritedClientId.toString());
      }
    }
  }, [inheritedClientId]);

  const saveUserDetails = async () => {
    const _users: User[] = users.map((user) => {
      return {
        owningCustomerId: owningCustomerId,
        status: user.status,
        id: user.id,
        isSignatory: true,
        userIdentity: {
          firstName: user.firstName,
          lastName: user.lastName,
          mobilePhone: user.phone,
          login: user.email,
          email: user.email,
          status: user.status,
          secondEmail: user.email,
        },
        address: {
          street1: user.street1,
          street2: user.street2,
          city: user.city,
          state: user.state,
          postalCode: user.postalCode,
          country: user.country?.code ?? '',
        },
      };
    });
    const { data, status } = await ClientService.createUsers(_users);
    if (status !== 200) {
      showToast('error', 'error', 'user.save.error');
      return;
    } else {
      data.forEach((item: any) => {
        const { result, data } = item;
        if (result.success) {
          showToast('success', 'success', 'signatory.user.save.success', {
            email: data.userIdentity.email,
          });
        } else {
          const { errors } = result;
          console.log(errors);
          showToast('error', 'error', (errors[0] && errors[0].errorTextCode) ?? '', {
            ...errors[0],
          });
          return;
        }
      });
    }

    if (data) {
      const filterData = data.filter(
        (item: { result: { success: boolean }; data: User }) => item.result.success,
      );
      const userData = filterData?.map((user: { data: User }) => user.data) as User[];
      fillUsersDatafromServer(userData);
    }
    if (onSaveAndProceed) {
      onSaveAndProceed();
    }
  };

  const addNewUser = async () => {
    const isValid = await trigger();
    if (isValid) {
      setUsers((prev) => {
        return [...prev, userDefaults];
      });
      setSelectedUserIndex(users.length);
      reset(userDefaults);
    }
  };

  const switchUser = async (index: number) => {
    const isValid = await trigger();
    if (isValid) {
      setSelectedUserIndex(index);
      reset(users[index]);
    }
  };

  const deleteUser = () => {
    const usersLeft = [...users];
    usersLeft.splice(selectedUserIndex, 1);
    const currentUserIndex = selectedUserIndex === 0 ? selectedUserIndex : selectedUserIndex - 1;
    setUsers(usersLeft);
    setSelectedUserIndex(currentUserIndex);
    reset(usersLeft[currentUserIndex]);
  };

  // const calculateProgress = (): number => {
  //   const requiredFields: Array<keyof UserDetailsFormData> = [
  //     'firstName',
  //     'email',
  //     'phone',
  //     'country',
  //     'postalCode',
  //   ];
  //   const totalFields = requiredFields.length;
  //   const totalFilledFields = users.reduce<number>((acc, user) => {
  //     const filledFields = requiredFields.filter(
  //       (field) => user[field] !== undefined && user[field] !== '',
  //     );
  //     return acc + filledFields.length;
  //   }, 0);

  //   const totalRequiredFields = totalFields * users.length;
  //   const progressPercentage =
  //     totalRequiredFields > 0 ? Math.round((totalFilledFields / totalRequiredFields) * 100) : 0;
  //   return progressPercentage;
  // };

  const showErrorToast = () => {
    showToast('error', 'user.validation.error', 'user.validation.error.detail');
  };

  useEffect(() => {
    console.log(users);
  }, [users]);
  // const progress = calculateProgress();

  return (
    <>
      <Toaster ref={toastRef} />
      <div className='rounded-c8 shadow-c bg-white p-8'>
        <div className='grid lg:grid-cols-2'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='user_details.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='user_details.description' />
            </div>
          </div>
          <div className='flex justify-end items-center'>
            {/* {activeStep === ClientForms.UserDetails && (
              <Progress value={progress} currentStep={2} stepLimit={3} />
            )} */}
            {/* <Checkmark className='ml-4' type={progress === 100 ? 'success' : 'info'} /> */}
          </div>
        </div>
        {activeStep === ClientForms.UserDetails || mode === AddOrEditOrView.View ? (
          <div>
            {((mode === AddOrEditOrView.View && customerHasUsers) ||
              mode !== AddOrEditOrView.View) && (
              <div className='mt-6 flex gap-2'>
                {users.map((user, index) => (
                  <Tag
                    key={Math.random()}
                    onClick={() => {
                      switchUser(index);
                    }}
                    className={`border px-4 py-2.5 ${selectedUserIndex === index ? 'bg-primary-surface-light border-primary-dark-content text-primary-dark-content' : 'border-neutral-5 bg-neutral-surface-subtle'} text-neutral-2 py-1 px-3 rounded-[20px] cursor-pointer`}
                  >
                    {`${translateWithValues('user')} ${index + 1}`}
                  </Tag>
                ))}
                {mode !== AddOrEditOrView.View &&
                  customerType !== CustomerType.INSTRUCTING_INSTITUTION && (
                    <Button
                      severity='info'
                      type='button'
                      className='!px-2 !py-1'
                      onClick={() => {
                        addNewUser();
                      }}
                    >
                      <UserFollow />
                    </Button>
                  )}
              </div>
            )}
            {((mode === AddOrEditOrView.View && customerHasUsers) ||
              mode !== AddOrEditOrView.View) && (
              <>
                {/*eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <form onSubmit={handleSubmit(saveUserDetails)}>
                  <div className='mx-5 mt-12 mb-6 grid md:grid-cols-2 lg:grid-cols-3 gap-5'>
                    <TextInput
                      formRegister={register('firstName', { required: true })}
                      disabled={
                        mode === AddOrEditOrView.View ||
                        customerType == CustomerType.INSTRUCTING_INSTITUTION
                      }
                      error={errors.firstName}
                      label='first_name'
                      placeholder='first_name'
                      isRequired
                    />
                    <TextInput
                      disabled={
                        mode === AddOrEditOrView.View ||
                        customerType == CustomerType.INSTRUCTING_INSTITUTION
                      }
                      formRegister={register('lastName', { required: true })}
                      error={errors.lastName}
                      label='last_name'
                      placeholder='last_name'
                      isRequired
                    />
                    <DropdownInput
                      disabled={
                        mode === AddOrEditOrView.View ||
                        customerType == CustomerType.INSTRUCTING_INSTITUTION
                      }
                      name='status'
                      label='status'
                      placeholder='status'
                      control={control}
                      rules={{ required: true }}
                      isRequired
                      error={errors.status}
                      options={STATUS_OPTIONS}
                    />
                    <TextInput
                      disabled={
                        mode === AddOrEditOrView.View ||
                        customerType == CustomerType.INSTRUCTING_INSTITUTION
                      }
                      formRegister={register('street1')}
                      error={errors.street1}
                      label='street_1'
                      placeholder='street_1'
                    />
                    <TextInput
                      disabled={
                        mode === AddOrEditOrView.View ||
                        customerType == CustomerType.INSTRUCTING_INSTITUTION
                      }
                      formRegister={register('street2')}
                      error={errors.street1}
                      label='street_2'
                      placeholder='street_2'
                    />
                    <TextInput
                      disabled={
                        mode === AddOrEditOrView.View ||
                        customerType == CustomerType.INSTRUCTING_INSTITUTION
                      }
                      formRegister={register('city')}
                      error={errors.street1}
                      label='city'
                      placeholder='city'
                    />
                    <TextInput
                      disabled={
                        mode === AddOrEditOrView.View ||
                        customerType == CustomerType.INSTRUCTING_INSTITUTION
                      }
                      formRegister={register('state')}
                      error={errors.street1}
                      label='state'
                      placeholder='state'
                    />
                    <CountrySelect
                      disabled={
                        mode === AddOrEditOrView.View ||
                        customerType == CustomerType.INSTRUCTING_INSTITUTION
                      }
                      label='country'
                      placeholder='country'
                      name='country'
                      control={control}
                      error={errors['country']}
                    />
                    <TextInput
                      disabled={
                        mode === AddOrEditOrView.View ||
                        customerType == CustomerType.INSTRUCTING_INSTITUTION
                      }
                      label='zip_code'
                      placeholder='zip_code'
                      formRegister={register('postalCode')}
                      error={errors['postalCode']}
                    />
                    <TextInput
                      formRegister={register('email', {
                        required: true,
                        pattern: {
                          value: EMAIL_VALIDATION_REGEX,
                          message: 'invalid_email_address',
                        },
                      })}
                      error={errors.email}
                      label='email_id'
                      placeholder='email_id'
                      disabled={!!users[selectedUserIndex].id || mode === AddOrEditOrView.View}
                      isRequired
                    />
                    <TextInput
                      disabled={
                        mode === AddOrEditOrView.View ||
                        customerType == CustomerType.INSTRUCTING_INSTITUTION
                      }
                      formRegister={register('phone', { required: true })}
                      error={errors.phone}
                      label='phone'
                      placeholder='phone'
                      isRequired
                    />
                  </div>
                  <div className='flex items-center justify-center'>
                    {!users[selectedUserIndex].id && users.length > 1 && (
                      <Button
                        type='button'
                        onClick={deleteUser}
                        severity='warning'
                        className='!px-7'
                      >
                        <Translate value='delete_user' />
                      </Button>
                    )}
                  </div>
                  {mode !== AddOrEditOrView.View &&
                    customerType !== CustomerType.INSTRUCTING_INSTITUTION && (
                      <div className='flex items-center justify-center mt-10 gap-5'>
                        <Button
                          type='button'
                          onClick={() => {
                            addNewUser();
                          }}
                          severity='contrast'
                          className='!px-7'
                        >
                          <Translate value='add_another' />
                        </Button>
                        <Button
                          disabled={isLoading}
                          type='submit'
                          severity='info'
                          onClick={() => {
                            if (handleStepperValidation)
                              handleStepperValidation(ClientForms.UserDetails, isValid);
                            if (!isValid) {
                              showErrorToast();
                            }
                          }}
                        >
                          <Translate value='save_and_proceed' />
                        </Button>
                      </div>
                    )}
                </form>
              </>
            )}
          </div>
        ) : (
          <div className='flex justify-between mt-4'>
            <Button
              severity='contrast'
              className='!px-14'
              disabled={!visitPermitted?.has(ClientForms.UserDetails)}
              onClick={() => {
                if (jumpToEdit) jumpToEdit(ClientForms.UserDetails);
              }}
            >
              <Translate
                value={
                  mode === AddOrEditOrView.Edit || visited?.has(ClientForms.UserDetails)
                    ? 'edit'
                    : 'start'
                }
              />
            </Button>
            {/* <Progress value={progress} currentStep={2} stepLimit={3} /> */}
          </div>
        )}
      </div>
    </>
  );
};

export default UserDetails;
